import React from 'react'

import Title from 'components/Title'
import { useSiteMetadata } from 'hooks/useSiteMetadata'
import Container from 'components/Container'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Footer from "components/Footer"
import Header from "components/Header"

export default () => {

    const { t } = useTranslation()

    const { title } = useSiteMetadata()

    return (
        <>
        <Header />
        <main>
            <Title 
                dark 
                title={ t(`Nothing to upgrade or patch ever`) }
                subtitle={ t('Get automatic security updates and avoid costly downtime from failed upgrades') } 
            />

            <Container>

                { 
                    t('{{ siteTitle }} is a Progressive Web App (PWA) that runs on everything from iOS and Android to Mac and Windows. Updates and patches are pushed to your devices automatically.', { siteTitle: title })
                }

            </Container>

            
        </main>
        <Footer/>
        </>
    )
}